import { Dropdown } from "../common/Dropdown";
import { TextField } from "../common/TextField";
import SearchIcon from "../../../../assets/icons/search.svg";
import RefreshIcon from "../../../../assets/icons/refresh.svg";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../Sidebar";
import { useRef, useEffect, useState } from "react";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import {
  GET_COUPONS,
  GET_SAM_SWITCH,
} from "../../../../service/apiEndPoints";
import { toast } from "react-toastify";
import moment from "moment";
import clsx from "clsx";
import ReLoginModal from "../common/ReloginModal";
import TablePagination from "../common/TablePagination";
import { refreshToken, validateToken } from "../../../App/utils/util";
import { InputDate } from "../common/InputDate";
import _ from "lodash";
import { CouponModal } from "./modal";

export const CouponPage = () => {
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);
  const [moduleLoader, setModuleLoader] = useState(true);
  const [couponModal, setCouponModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [isReloginOpen, setReloginIsOpen] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [selectedOs, setSelectedOs] = useState("all");
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [fileName, setFileName] = useState("+ CSV File");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [initialData, setInitalData] = useState({
    productType: "watch",
    product: 0,
    type: "",
    file: "",
  });
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
    getProductList("watch");
  }, [limit, page]);

  const loadData = async ( specificItem: any = null ) => {
    if( validateToken() === false ){ navigate("/login")}
    setLoader(true);
    const URL = GET_COUPONS.replace(":limit", limit.toString())
      .replace(":page", page.toString())
      .replace(":os", selectedOs === "all" ? "" : selectedOs)
      .replace(":name_sku", search)
      .replace(":date", date ? moment(date).format("YYYY/MM/DD") : "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          setData(res?.data);
          setTotalCount(res?.pagination?.total);
          setTotalPages(res?.pagination?.total_page);
          setRefreshed(false);
          setLoader(false);
        } else {
          if (res?.data?.message === "record not found") {
            setData([]);
            setTotalCount(0);
            setTotalPages(0);
            setRefreshed(false);
            setLoader(false);
          }
        }
      },
      async (err: any) => {
        if (err === "unauthorized" && !refreshed) {
          const check = await refreshToken();
          if (check) {
            setRefreshed(true);
            loadData();
          } else setReloginIsOpen(true);
        } else if (err === "unauthorized") {
          setReloginIsOpen(true);
        } else {
          console.log(err);
          setLoader(false);
          toast.error("something went wrong!");
        }
      },
      METHOD.GET,
      {}
    );
  };

  const getProductList = (value: string) => {
    setModuleLoader(true);
    const URL = GET_SAM_SWITCH.replace(":type", value)
      .replace(":limit", "99999")
      .replace(":page", "")
      .replace(":os", "")
      .replace(":name_sku", "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          setProductData(
            res?.data?.sort((a: any, b: any) => a.name.localeCompare(b.name))
          );
          var data = {
            productType: value,
            product: 0,
            type: res?.data?.[0]?.os?.[0],
            file: "",
          };
          setSelectedProduct(res?.data?.[0]);
          setInitalData(data);
          setModuleLoader(false);
        } else {
          if (res?.data?.message === "record not found") {
            setProductData([]);
            setModuleLoader(false);
          }
        }
      },
      (err: any) => {
        console.log(err);
        toast.error("something went wrong!");
      },
      METHOD.GET,
      {}
    );
  };

  useEffect(() => {
    setInitalData((prev) => ({ ...prev, type: selectedProduct?.os?.[0] }));
  }, [selectedProduct]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setPage(0);
    loadData();
  };
  return (
    <div className="flex w-full">
      <SideBar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        fromCoupon={true}
      />
      <div
        className={clsx(
          "mainClass relative flex flex-col justify-center overflow-auto",
          collapsed
            ? "ml-12 lg:ml-14 xl:ml-14 w-full"
            : "ml-12 w-full md:ml-[13em]"
        )}
      >
        <div className="flex w-full flex-row justify-between">
          <span className="text-3xl font-bold p-4">Coupon</span>
          <button
            // onClick={() => setCouponModal(true)}
            className="text-white bg-sky-500 hover:bg-sky-600 font-medium rounded-lg text-sm m-4 mt-5 px-2 py-2.5 w-24"
          >
            Register(Deprecated)
          </button>
        </div>

        <div className="flex flex-row justify-between align-middle mt-14 ml-5 mr-3 md:ml-10 md:mr-5">
          <form
            className="flex flex-col md:flex-row justify-between w-full"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col lg:flex-row ">
              <div className="mr-4 flex flex-row">
                <Dropdown
                  className="mr-4"
                  for="search"
                  onChange={(e: string) => setSelectedOs(e)}
                  items={[
                    { key: "All", value: "all" },
                    { key: "Galaxy Store", value: "tizen" },
                    { key: "Google Play", value: "wear" },
                  ]}
                  label="Store"
                ></Dropdown>
                <InputDate
                  label="Expired Date"
                  placeholder="yyyy/mm/dd"
                  for="search"
                  value={date}
                  onchange={(e: any) => setDate(e)}
                ></InputDate>
              </div>
              <TextField
                label="Product Name or Coupon Code"
                placeholder="Product Name or Coupon Code"
                for="search"
                onchange={(e: string) => setSearch(e)}
              ></TextField>
            </div>
            <div className="[&>*]:mr-2 [&>*]:hover:cursor-pointer mr-0 mt-8 flex flex-row h-10">
              <button>
                <img src={SearchIcon} alt="" />
              </button>
              <img src={RefreshIcon} onClick={loadData} alt="" />
            </div>
          </form>
        </div>
        <div className="divide-y mt-5">
          <div />
          <div />
        </div>
        <div className="ml-5 mr-3 md:ml-10 md:mr-5 mt-5 flex flex-row justify-between">
          <div className=" flex flex-row">
            <span className="font-bold">Items:</span>
            <span className="text-[#697274] ml-2 animate-pulse">
              {loader ? (
                <div className="h-2 w-4 bg-slate-600 rounded mt-2" />
              ) : (
                totalCount
              )}
            </span>
          </div>
          <Dropdown
            className="-mt-4 -mb-2 mr-2"
            for="search"
            onChange={(e: number) => setLimit(e)}
            items={[
              { key: "10", value: 10 },
              { key: "15", value: 15 },
              { key: "20", value: 20 },
              { key: "25", value: 25 },
              { key: "50", value: 50 },
              { key: "100", value: 100 },
              ,
            ]}
          />
        </div>
        <div className="ml-5 mr-3 md:ml-10 md:mr-7 mt-5 overflow-auto">
          <table className="w-full [&>*]:border">
            <thead className="">
              <tr className="bg-[#EFF7FD] [&>*]:p-5 [&>*]:border">
                <th>No</th>
                <th>Product Name</th>
                <th>Product SKU</th>
                <th>Type</th>
                <th>Coupon Code</th>
                <th>Country</th>
                <th>Store</th>
                <th>Expired Date</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody className="[&>*]:border">
              {loader ? (
                <tr className="[&>*]:border-r-[1px] animate-pulse">
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                  <td>
                    <div className="h-2 bg-slate-700 rounded" />
                  </td>
                </tr>
              ) : !data.length ? (
                <tr className="[&>*]:border-r-[1px]">
                  <td colSpan={9} className="font-bold text-gray-700">
                    No Data to Show
                  </td>
                </tr>
              ) : (
                data.map((e: any, i) => (
                  <tr className="[&>*]:border-r-[1px]">
                    <td>{(page - 1) * limit + ++i}</td>
                    <td>{e?.name}</td>
                    <td>{e?.sku}</td>
                    <td>{e?.type}</td>
                    <td>{e?.coupon_code}</td>
                    <td>{e?.country}</td>
                    <td>
                      {e?.os?.includes("wear") && "Google Play"}
                      {e?.os?.includes("wear") &&
                        e?.os?.includes("tizen") &&
                        ", "}
                      {e?.os?.includes("tizen") && "Galaxy Store"}
                    </td>
                    <td>
                      {moment(e?.expired_date).format("YYYY/MM/DD HH:mm:ss")}
                    </td>
                    <td>
                      {moment(e?.created_at).format("YYYY/MM/DD HH:mm:ss")}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {loader || totalPages <= 1 ? undefined : (
          <TablePagination
            curPage={page}
            setCurPage={setPage}
            lastPage={totalPages}
          />
        )}
      </div>
        <CouponModal 
          couponModal = { couponModal }
          setCouponModal = { setCouponModal }
          productData = { productData }
          getProductList = { getProductList }
          selectedProduct = { selectedProduct }
          setSelectedProduct = { setSelectedProduct }
          fileName = { fileName }
          setFileName = { setFileName }
          initialData = { initialData }
          setReloginIsOpen = { setReloginIsOpen }
          moduleLoader = { moduleLoader }
          setModuleLoader = { setModuleLoader }
        />
      <ReLoginModal isOpen={isReloginOpen} setIsOpen={setReloginIsOpen} />
    </div>
  );
};
