import { TextField } from "../common/TextField"
import SearchIcon from "../../../../assets/icons/search.svg"
import RefreshIcon from "../../../../assets/icons/refresh.svg";

interface YamWatchSearchData {
    search: string
    setSearch: React.Dispatch<React.SetStateAction<string>>
    setPage: React.Dispatch<React.SetStateAction<number>>
    loadData: () => void
}

export const YamWatchSearch = (props: YamWatchSearchData) => {
    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.setPage(0);
        props.loadData();
    }
    return (
        <>
        <div className="flex flex-row justify-between align-middle mt-14 ml-5 mr-3 md:ml-10 md:mr-5">
            <form
                className="flex flex-col md:flex-row justify-between w-full"
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col md:flex-row ">
                <TextField
                    label="Name or SKU"
                    placeholder="Name or SKU"
                    for="search"
                    onchange={(e: string) => props.setSearch(e)}
                ></TextField>
                </div>
                <div className="[&>*]:mr-2 [&>*]:hover:cursor-pointer mr-0 mt-8 flex flex-row h-10">
                <button>
                    <img src={SearchIcon} alt="" />
                </button>
                <img src={RefreshIcon} onClick={props.loadData} alt="" />
                </div>
            </form>
            </div>
            <div className="divide-y mt-5">
                <div />
                <div />
            </div>
        </>
    )
}
