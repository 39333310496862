import { Link } from "react-router-dom"

export const YamWatchNavigation = () => {
    return (
        <div className="flex w-full flex-row justify-between">
            <span className="text-3xl font-bold p-4">Yam Watch</span>
            <Link
                className="text-white bg-[#0EB4F3] hover:bg-[#58c7f3] font-medium text-center rounded-lg h-fit text-sm m-4 mt-5 px-2 py-2.5 w-24"
                to="/dashboard/yamwatch/register"
            >
                Register
            </Link>
        </div>
    )

}