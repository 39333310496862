export enum ThemeName {
  SAM_WATCH = "samwatch",
  SAM_THEME = "samtheme",
  SAM_STUDIO = "samstudio",
  SAM_ABOUT = "samabout",
  YAM_WATCH = "yamwatch",
}

export enum Description {
  SAM_WATCH = `SamWatch beautifully decorates your smartwatch. 
We are trying to provide a variety of watch faces that suit your lifestyle and differentiated designs. 
Feel the sensuous design of SamWatch.`,
  YAM_WATCH = `YamWatch beautifully decorates your smartwatch. 
We are trying to provide a variety of watch faces that suit your lifestyle and differentiated designs.
Feel the sensuous design of YamWatch.`,
  SAM_THEME = `This Theme is Wonderful!!! 
SamTheme is Fashionable. 
Full of good workmanship.
  
www.facebook.com/SamtreePage
https://t.me/SamWatch_SamTheme`,
}

export type Pagination = {
  column: string;
  limit: number;
  offset: number;
  order: string;
  page: number;
  query: string;
  sort: string;
  total: number;
  total_page: number;
};
