import IconPreview from "./IconPreview"
import InputIcon from "./Input"

import "../IconGen.css"

const IconContainer = (props: any) => {
    return (
        <div className="watch-img-container">
            <div id="preview" className="watch-img-preview-box" >
                <IconPreview 
                    imgFile={props.imgFile}
                    clickedButton={props.clickedButton}
                    setImgContainerRef={props.setImgContainerRef}
                />
            </div>
            <div>
                <InputIcon 
                    setImgFile={props.setImgFile} 
                    setFileName={props.setFileName}
                    fileName={props.fileName}
                />
            </div>
        </div>
    )
}

export default IconContainer