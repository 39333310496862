import { useNavigate } from "react-router-dom";
import LogoutIcon from "../../../../assets/icons/logout.svg";
import Logo from "../../../../assets/icons/samtree-logo.png";
import { Link } from "react-router-dom";
import "./index.css";
import {
  removeDataFromLocalStorage,
} from "../../../App/utils/util";
import { NavigationButtons } from "./navigationButtons";

export const SideBar = (props: {
  collapsed: any;
  setCollapsed: (arg0: boolean) => void;
  fromCoupon: boolean;
}) => {

  const navigate = useNavigate();
  // 컬랩스가 디폴트로 설정되서 최대한 수정을 덜하기 위해 true로 기본 값을 함
  props.setCollapsed(true)
  return (
    <>
      <div className="fixed w-12 md:w-14 lg:w-14 xl:w-14 maxHeight bg-[#27324D] border-r-2 border-[#394564] z-10">
        <div className="h-full flex flex-col justify-between">
          <NavigationButtons />          
          <div className="settings-group flex flex-col items-center z-10">
            <hr className="w-full border-[#394564]"></hr>
            <Link
              onClick={() => {
                removeDataFromLocalStorage("login-auth-token");
                removeDataFromLocalStorage("isLoggedIn")
                removeDataFromLocalStorage("email")
                removeDataFromLocalStorage("refresh-token")
                navigate("/", { replace: true });
              }}
              to="/"
            >
              <img src={LogoutIcon} alt="" />
            </Link>
            <Link to="/">
              <img className="w-9" src={Logo} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
