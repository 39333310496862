export const SAM_TYPES = {
    ALL : 'all',
    TIZEN : 'tizen',
    WEAR : 'wear'
    
}

export const MENU_TYPE = {
    SAM_WATCH: "samWatch",
    YAM_WATCH: "yamWatch"
}

export const BROWSER_TYPES = {
    CHROME: "chrome",
    SAFARI: "safari",
    FIREFOX: "firefox"
}