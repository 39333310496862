import { useState } from "react"
import "./IconGen.css"
import IconContainer from "./Container/IconContainer"
import Buttons from "./Container/Buttons"
import domtoimage from "dom-to-image"
import saveAs from "file-saver"
import { getBrowserInfo } from "../../../App/utils/util"
import { BROWSER_TYPES } from "../../../App/utils/constants"
import html2canvas from "html2canvas"

const IconGenerator = () => {
    
    const [ imgFile, setImgFile ] = useState("")
    const [ clickedButton, setClickedButton ] = useState("")
    const [ imgContainerRef, setImgContainerRef ] = useState("")
    const [ fileName, setFileName ] = useState("파일이 선택되지 않음")

    const removeExtention = (fileName:string) => {
        return fileName.replace(/(.png|.jpg|.jpeg|.gif)$/,'')
    }

    const onDownloadBtn = () => {
        if( !!clickedButton === false ) { alert("쿠폰 유형을 선택 후 다시 시도해주세요."); return}

        const querySelector = document.querySelector("#preview") as HTMLElement
        if( getBrowserInfo()?.name === BROWSER_TYPES.SAFARI ) {

            html2canvas( querySelector, { backgroundColor: null, scale: 1 } )
                .then( (canvas: any) => {
                    const saveConfirm = window.confirm("다운로드 하시겠습니까?")
                    if( saveConfirm ) {
                        saveAs( canvas.toDataURL("image/png"), `${removeExtention(fileName)}_${clickedButton}.png` )
                    }
                })
                .catch( err => {
                    alert("사진을 선택 후 다시 시도해주세요.")
                    console.log(err)
                })
        } else {
            domtoimage
            .toBlob( querySelector )
            .then( (blob: any) => {
                const saveConfirm = window.confirm("다운로드 하시겠습니까?")
                if( saveConfirm ) {
                    saveAs( blob, `${removeExtention(fileName)}_${clickedButton}.png`)
                }
            })
            .catch( err => {
                alert("사진을 선택 후 다시 시도해주세요.")
                console.log(err)
            })
        }


    }

    return (
        <div className="watch-img bg-black">
            <div className="watch-img-body">
                <div style={{"gridArea": "img"}}>
                    <IconContainer 
                        imgFile={imgFile}
                        fileName={fileName}
                        setFileName={setFileName}
                        setImgFile={setImgFile}
                        clickedButton={clickedButton}
                        imgContainerRef={imgContainerRef}
                        setImgContainerRef={setImgContainerRef}
                    />
                </div>
                <div style={{"gridArea": "buttons"}}>
                    <Buttons 
                        setClickedButton={setClickedButton}
                        download={onDownloadBtn}
                    />
                </div>
            </div>
        </div>
    )
}

export default IconGenerator