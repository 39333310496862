import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { SelectTime } from "../CalendarModal/selectTime";
import dayjs, {Dayjs} from "dayjs";
import { Button, TextField } from "@mui/material";
import { X } from "react-feather";
import { handleSaleSubmit } from "./handleSubmit";

interface SelectTimeModalData {
    sku: string
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SelectTimeModalBody = (props: SelectTimeModalData) => {
    const [calendarStartTimeValue, setCalendarStartTimeValue] = useState<Dayjs | null>(dayjs('0000-00-00T00:00'));
    const [calendarEndTimeValue, setCalendarEndTimeValue] = useState<Dayjs | null>(dayjs('0000-00-00T00:00'));
    const [calendarStartDateValue, setCalendarStartDateValue] = useState<Dayjs | null>(dayjs(new Date()));
    const [calendarEndDateValue, setCalendarEndDateValue] = useState<Dayjs | null>(dayjs(new Date()));
    const [perSale, setPerSale] = useState<string>("")
    const [submitDisabled, setDisabled] = useState<boolean>(true)
    const saleRegex = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const regex = /^[0-9\b]+$/;
        if( regex.test(e.target.value) || e.target.value === ""){
            setPerSale(e.target.value);
            switch(e.target.value) {
                case "" : setDisabled(true); return
                case "0": setDisabled(true); return
            }
            setDisabled(false)
        } else { setDisabled(true) }
    }

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => props.setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[40%] min-w-[720px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                                >
                                    <div style={{ alignItems: "center", justifyContent: "space-between"}} className="flex">
                                        <div>
                                            세일 설정
                                        </div>
                                        <div
                                            style={{ cursor: "pointer"}}
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <X className="text-white sm:h-8 sm:w-8 bg-[#d23434] rounded shadow-md hover:cursor-pointer" />
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="mt-4">
                                <p className="text-sm text-gray-500">
                                    <div style={{ marginBottom: "10px"}}>
                                        <SelectTime 
                                            label="시작 날짜 및 시간"
                                            date={calendarStartDateValue}
                                            setDate={setCalendarStartDateValue}
                                            value={calendarStartTimeValue}
                                            setValue={setCalendarStartTimeValue}
                                        />
                                    </div>
                                    <div>
                                        <SelectTime 
                                            label="종료 날짜 및 시간"
                                            date={calendarEndDateValue}
                                            setDate={setCalendarEndDateValue}
                                            value={calendarEndTimeValue}
                                            setValue={setCalendarEndTimeValue}
                                        />
                                    </div>
                                    
                                </p>
                                </div>
                                <div className="mt-4 flex" style={{ gap:"1rem", justifyContent: "flex-end"}}>
                                    <div>
                                        <TextField
                                            type="number"
                                            id={props.sku + "per_sale"}
                                            label="세일률"
                                            variant="outlined"
                                            onChange={(e) => saleRegex(e)}
                                            value={perSale}
                                        />
                                    </div>
                                    <Button
                                        style={{width: "200px"}}
                                        variant="contained"
                                        disabled={submitDisabled}
                                        onClick={() => handleSaleSubmit(
                                            calendarStartTimeValue, 
                                            calendarStartDateValue,
                                            calendarEndTimeValue,
                                            calendarEndDateValue,
                                            props.sku,
                                            perSale
                                            )
                                        }
                                    >
                                        저장
                                    </Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}