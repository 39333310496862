import { useRef } from "react"
import "../IconGen.css"

const InputIcon = (props: any) => {

    const iconRef = useRef<HTMLInputElement | null>(null)
    const uploadImg = (e: React.ChangeEvent<HTMLInputElement>) => { 
        let file = (iconRef.current?.files as FileList)[0]
        if( file !== undefined ) {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onloadend = () => { props.setImgFile(reader.result) }

            props.setFileName(file["name"])
        }
    }

    return (
        <div className="img-upload">
            <span> { props.fileName } </span>
            <label className="select-img text-white" htmlFor="upload"> 사진 선택 </label>
            <input 
                type="file"
                accept="image/*"
                id="upload"
                onChange={ uploadImg }
                ref={ iconRef }
            />
        </div>
    )
}

export default InputIcon