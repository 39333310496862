import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "./selectTime.css"

interface SelectTimeData {
    label: string
    date: dayjs.Dayjs | null
    setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
    value: dayjs.Dayjs | null
    setValue: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
}

export const SelectTime = (props: SelectTimeData) => {
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='select-time-flex'>
                <div>
                    {`${props.label} * GMT+0`} 
                </div>
                <div className='select-time-flex'>
                    <div>
                        <DatePicker 
                            label="날짜 선택"
                            value={props.date}
                            onChange={newDate => props.setDate(newDate)}
                        />
                    </div>
                    <div>
                        <TimePicker 
                            label="시간 선택"
                            value={props.value}
                            onChange={newValue => props.setValue(newValue)}
                        />
                    </div>
                    
                </div>
            </div>
        </LocalizationProvider>
    )
}