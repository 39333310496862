export const newIntersectionObserver = ( callback: any) => {

    const intersectionObserver = ( entries: IntersectionObserverEntry[], io: IntersectionObserver ) => {
        entries.forEach( entry => {
        if( entry.isIntersecting ) {
                io.unobserve(entry.target)
                callback()
            }
        })
    }

    return new IntersectionObserver(intersectionObserver, {threshold: 1.0})
}