import "../IconGen.css"

const Buttons = (props: any) => {
    const onChange = (e: any) => {
        switch( e.target.id ) { 
            case "get":
                props.setClickedButton("get")
                break

            case "now":
                props.setClickedButton("now")
                break
            default:
                props.setClickedButton("get")
                break
        }
    }

    return (
        <div className="icon-down-body">
            <div id="get" className="icon-down-button" onClick={ onChange } >
                Get Free Coupon
            </div>
            <div id="now" className="icon-down-button" onClick={ onChange } >
                Now Free
            </div>
            <div id="download" className="icon-down-button" onClick={ props.download } >
                아이콘 다운로드
            </div>
        </div>
    )
}

export default Buttons