import now from "../../../../../assets/icons/IconGen/nowFreeButton.png"
import get from "../../../../../assets/icons/IconGen/getFreeButton.png"
import "../IconGen.css"

const IconPreview = (props: any) => {
    return (
        <div>
            <div style={{ position:"relative"}}>
                <img alt="" src={ props.imgFile ? props.imgFile : null} className="icon-gen" />
                <div>
                    { 
                        props.imgFile ?
                            props.clickedButton === "get" 
                            ? 
                                <img alt="" className="icon-gen" src={ get }  />
                            : props.clickedButton === "now"
                            ?
                                <img alt="" className="icon-gen" src={ now }  />
                            : null
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default IconPreview