import { Fragment, useEffect, useRef, useState } from "react";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { CREATE_COUPONS, } from "../../../../service/apiEndPoints";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { Field, Form, Formik } from "formik";
import { refreshToken, } from "../../../App/utils/util";
import _ from "lodash";

export const CouponModal = ( (props: any) => { 

  // 코드에서 사용되지 않는 변수도 있고 form 데이터가 조회가 되지 않음
  // 처음 initialData를 바꾸는 방법으로 조정
  useEffect(() => {
    if( !!props.selectedProduct !== false) {
      setInitialData({
        productType: "watch",
        product: 0,
        type: props.selectedProduct.os[0],
        file: ""
      })
    }

  }, [props.selectedProduct])
  

  const [initialData, setInitialData] = useState<InitialData>({
    productType: "watch",
    product: 0,
    type: "",
    file: "",
  });
  const [fileName, setFileName] = useState("+ CSV File");

  interface InitialData {
    productType: string,
    product: number
    type: string,
    file: string
  }

  const cancelButtonRef = useRef(null);

  const getProductList = (value: string) => {
    setInitialData({
      productType: value,
      product: 0,
      type: props.selectedProduct.os[0],
      file: ""
    })
  }

  return (
    <Transition.Root show={props.couponModal} as={Fragment}>
      <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
              props.setCouponModal(false);
              getProductList("watch");
              setFileName("+ CSV File");
          }}
      >
      <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
      >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8  max-w-fit">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex justify-center">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-bold text-gray-900"
                      >
                        Upload CSV File
                      </Dialog.Title>
                      <Formik
                        initialValues={initialData}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                          if( fileName === "+ CSV File" ) {
                            toast.error("CSV 파일을 업로드 후 다시 시도해주십시오.")
                            return
                          }
                          let formData = new FormData();
                          formData.append(
                            "sam_switch_sku",
                            props.selectedProduct.sku
                          );
                          formData.append("os", values.type);
                          formData.append("file", values.file);
                          apiCall(
                            CREATE_COUPONS,
                            formData,
                            (res: any) => {
                              if (res?.success) {
                                toast.success(res?.message);
                                props.setCouponModal(false);
                                getProductList("watch");
                                setFileName("+ CSV File");
                                props.setIsUploadTarget( (prev: any) => {
                                  return{ ...prev, [props.selectedProduct.sku]: true }
                                })
                                props.loadData()
                                // navigate("/dashboard/coupon");
                              }
                            },
                            async (err: any) => {
                              if (err === "unauthorized") {
                                const check = await refreshToken();
                                if (check) {
                                  // props.setRefreshed(true);
                                  apiCall(
                                    CREATE_COUPONS,
                                    formData,
                                    (res: any) => {
                                      if (res?.success) {
                                        toast.success(res?.message);
                                        // navigate("/dashboard/coupon");
                                      }
                                    },
                                    (err: any) => {
                                      console.log(err);
                                      toast.error(err?.data?.message);
                                    },
                                    METHOD.POST,
                                    {}
                                  );
                                } else {
                                  props.setReloginIsOpen(true);
                                }
                              } else {
                                console.log(err);
                                toast.error(err?.data?.message);
                              }
                            },
                            METHOD.POST,
                            {}
                          );
                        }}
                      >
                        {({ setFieldValue }) => (
                          <Form>
                            <div className="flex flex-col mt-10 ">
                              <label className="font-semibold mb-2">
                                Product Type
                              </label>
                              <Field
                                placeholder="Select a Product Type"
                                as="select"
                                name="productType "
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm w-80 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                onChange={(event: any) => {
                                  getProductList(event.target.value);
                                  setFieldValue(
                                    "productType",
                                    event.target.value
                                  );
                                }}
                              >
                                {
                                  props.selectedProduct.type === "yam" ? 
                                    <>
                                      <option value="watch">Yam Watch</option>
                                    </>
                                  :
                                    <>
                                      <option value="watch">Sam Watch</option>
                                      <option value="theme">Sam Theme</option>
                                    </>
                                }
                                
                              </Field>
                              <label className="font-semibold mb-2">
                                Product Name
                              </label>
                                <Field
                                  as="select"
                                  name="product"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                                  onChange={(event: any) => {
                                    setFieldValue(
                                      "product",
                                      event.target.value
                                    );
                                  }}
                                >
                                  <option value={0}>
                                    { props.selectedProduct?.name}
                                  </option>
                                </Field>
                              <label className="font-semibold mb-2">
                                Store
                              </label>
                                <Field
                                  as="select"
                                  name="type"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                                >
                                  {props.selectedProduct?.os?.map(
                                    (product: any) => (
                                      <option value={product}>
                                        {product === "tizen"
                                          ? "Galaxy Store"
                                          : product === "apple"
                                            ? "Apple Store"
                                            : "Google Play"}
                                      </option>
                                    )
                                  )}
                                </Field>
                              <div className="h-60 border w-80 mt-10 truncate text-center">
                                <p className="z-0 absolute text-gray-500 w-60 mx-10 text- mt-[6.5rem]">
                                  {_.truncate(fileName)}
                                </p>
                                <input
                                  className="z-10 opacity-0 w-full h-full hover:cursor-pointer"
                                  id="file"
                                  name="file"
                                  type="file"
                                  accept=".csv"
                                  onChange={(event) => {
                                    if (!event?.target?.files?.[0]) return;
                                    setFileName(
                                      event?.target?.files?.[0].name
                                    );
                                    setFieldValue(
                                      "file",
                                      event?.target?.files?.[0]
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="mt-5 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                              <button
                                style={{ background: fileName === "+ CSV File" || !fileName ? "gray": ""  }}
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                // disabled={ fileName === "+ CSV File" ? true : false}
                                // onClick={() => {
                                //   props.setCouponModal(false);
                                //   getProductList("watch");
                                //   setFileName("+ CSV File");
                                // }}
                              >
                                Upload
                              </button>
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-sky-500 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                  props.setCouponModal(false);
                                  getProductList("watch");
                                  setFileName("+ CSV File");
                                }}
                                ref={cancelButtonRef}
                              >
                                Cancel
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

}) 