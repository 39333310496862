import dayjs from "dayjs"


export const handleSaleSubmit = (
        calendarStartTimeValue: dayjs.Dayjs | null,
        calendarStartDateValue: dayjs.Dayjs | null,
        calendarEndTimeValue: dayjs.Dayjs | null,
        calendarEndDateValue: dayjs.Dayjs | null,
        sku: string,
        perSale: string
    ) => {


        console.log(calendarStartDateValue?.year(), calendarStartDateValue?.month(), calendarStartDateValue?.day() )

        console.log( new Date(
            `${calendarStartDateValue?.year()}-${calendarStartDateValue?.month()}-${calendarStartDateValue?.day()} ${calendarStartTimeValue?.hour()}:${calendarStartTimeValue?.minute()}`
        ).getTime())
}