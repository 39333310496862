import { SideBar } from "../Sidebar"
import { useState } from "react";
import { YamWatchTable } from "./yamwatchTable";
import clsx from "clsx";
import { YamWatchNavigation } from "./navigation";
import { CouponModal } from "../Coupon/modal";
import { validateToken } from "../../../App/utils/util";
import { useNavigate } from "react-router-dom";
import { GET_SAM_SWITCH } from "../../../../service/apiEndPoints";
import { METHOD, apiCall } from "../../../../service/baseApiCall";
import { toast } from "react-toastify";
import { ToggleTmp } from "../common/interface"
import ReLoginModal from "../common/ReloginModal";

export const YamWatchAdmin = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [isUploadTarget, setIsUploadTarget] = useState<any>([])
    const [selectedProduct, setSelectedProduct] = useState<any>()
    const [couponModal, setCouponModal] = useState(false); 
    const [isReloginOpen, setReloginIsOpen] = useState(false);
    const [search, setSearch] = useState("")
    const [loader, setLoader] = useState(true)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [toggleTmpVal, setToggleTmpVal] = useState<ToggleTmp>({})   
    

    const navigate = useNavigate()

    const loadData = () => {
        if( validateToken() === false ) { navigate("/login") }
        setLoader(true);
        const URL = GET_SAM_SWITCH.replace(":type", "yam")
        .replace(":limit", limit.toString())
        .replace(":page", page.toString())
        .replace(":os", "")
        .replace(":name_sku", search);
        apiCall(
        URL,
        {},
        (res: any) => {
            if (res?.success) {
                setData(res?.data);
                setTotalCount(res?.pagination?.total);
                setTotalPages(res?.pagination?.total_page);

                let tmpArr: ToggleTmp = {} 
                res?.data.forEach( (elem: any)=> {
                    tmpArr[elem.sku] = { 
                        "enable_get_coupon": elem.enable_get_coupon, 
                        "enable_now_coupon": elem.enable_now_coupon,
                        "enable_free_coupon": elem.enable_free_coupon,
                    } 
                })
                setToggleTmpVal(tmpArr)

                setLoader(false);
            } else {
                if (res?.data?.message === "record not found") {
                    setData([]);
                    setTotalCount(0);
                    setLoader(false);
                }
            }
        },
        (err: any) => {
            console.log(err);
            setLoader(false);
            toast.error("something went wrong!");
        },
        METHOD.GET,
        {}
        );
    };

    return (
        <div className="flex w-full">
            <SideBar 
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                fromCoupon={false}
            />

            <div
                className={clsx(
                "mainClass relative flex flex-col justify-center overflow-auto",
                collapsed
                    ? "ml-12 lg:ml-14 xl:ml-14 w-full"
                    : "ml-12 w-full md:ml-[13em]"
                )}
            >
                <YamWatchNavigation />
                <YamWatchTable 
                    isUploadTarget={isUploadTarget}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    couponModal={couponModal}
                    setCouponModal={setCouponModal}
                    search={search}
                    setSearch={setSearch}
                    loadData={loadData}

                    limit={limit}
                    page={page}
                    data={data}
                    totalCount={totalCount}
                    totalPages={totalPages}
                    toggleTmpVal={toggleTmpVal}
                    loader={loader}
                    setPage={setPage}
                    setLimit={setLimit}
                    setToggleTmpVal={setToggleTmpVal}
                />
            </div>
            <CouponModal 
                couponModal={couponModal}
                setCouponModal={setCouponModal}
                setReloginIsOpen={setReloginIsOpen}
                selectedProduct={selectedProduct}
                setIsUploadTarget={setIsUploadTarget}
                loadData={loadData}
            />
            <ReLoginModal isOpen={isReloginOpen} setIsOpen={setReloginIsOpen} />
            
        </div>
    )
}