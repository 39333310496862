import { Dropdown } from "../common/Dropdown"
import moment from "moment"
import { couponManage} from "../../../App/utils/util"
import Switch from "@mui/material/Switch"
import { toast } from "react-toastify"
import { METHOD, apiCall } from "../../../../service/baseApiCall"
import { DELETE_COUPON} from "../../../../service/apiEndPoints"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { YamWatchSearch } from "./search"
import TablePagination from "../common/TablePagination"
import { ToggleTmp } from "../common/interface"

interface YamWatchTableData {
    loadData: () => void
    isUploadTarget: any
    selectedProduct: any
    setSelectedProduct: React.Dispatch<any>
    couponModal: boolean
    setCouponModal: React.Dispatch<React.SetStateAction<boolean>> 
    search: string
    setSearch: React.Dispatch<React.SetStateAction<string>>

    limit: number
    page: number
    data: never[]
    totalCount: number
    totalPages: number
    toggleTmpVal: ToggleTmp
    loader: boolean
    setPage: React.Dispatch<React.SetStateAction<number>>
    setLimit: React.Dispatch<React.SetStateAction<number>>
    setToggleTmpVal: React.Dispatch<React.SetStateAction<ToggleTmp>>
}

export const YamWatchTable = (props: YamWatchTableData) => {
    const navigate = useNavigate()
    
    const handleUpload = ( (e:any) => {
        if( e?.coupon_enabled || props.isUploadTarget[e.sku] ) {
            toast.error("업로드된 아이템은 CSV 업로드가 불가능합니다.")
            return
        }
        props.setSelectedProduct(e)
        props.setCouponModal(!props.couponModal)
    })

    const handleDelete = (e: any) => {
        if( !e?.coupon_enabled || props.isUploadTarget[e.sku] === false ) {
            toast.error("업로드 되지 않은 아이템은 삭제가 불가능합니다.")
            return
        }

        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCall(DELETE_COUPON.replace(":sku", e?.sku),
                {},
                (res: any) => {
                    props.loadData()
                    toast.success("쿠폰을 성공적으로 삭제하였습니다.")
                },
                (err: any) => {
                    console.log(err)
                    if( err === "unauthorized" ) {
                        toast.error("토큰이 만료되었습니다. 다시 로그인 해주세요.")
                    } else {
                        toast.error("쿠폰 삭제를 실패하였습니다.")
                    }
                },
                METHOD.DELETE
            )
        }
    }

    useEffect(() => {
        props.loadData()
    }, [props.limit, props.page])

    return (
        <>
        <YamWatchSearch 
            search={props.search}
            setSearch={props.setSearch}
            setPage={props.setPage}
            loadData={props.loadData}
        />
        <div className="ml-5 mr-3 md:ml-10 md:mr-5 mt-5 flex flex-row justify-between">
            <div className=" flex flex-row">
                <span className="font-bold">Items:</span>
                <span className="text-[#697274] ml-2 animate-pulse">
                { props.loader ? (
                    <div className="h-2 w-4 bg-slate-600 rounded mt-2" />
                ) : (
                    props.totalCount
                )}
                </span>
            </div>
            <Dropdown
                className="-mt-4 -mb-2 mr-2"
                for="search"
                onChange={(e: number) => props.setLimit(e)}
                items={[
                { key: "10", value: 10 },
                { key: "15", value: 15 },
                { key: "20", value: 20 },
                { key: "25", value: 25 },
                { key: "50", value: 50 },
                { key: "100", value: 100 },
                ]}
            />
        </div>
        <div className="ml-5 mr-3 md:ml-10 md:mr-7 mt-5 overflow-auto">
            <table className="w-full [&>*]:border">
                <thead className="">
                <tr className="bg-[#EFF7FD] [&>*]:p-5 [&>*]:border">
                    <th>No</th>
                    <th>
                        <div style={{ fontSize: "10pt" }}>
                        (Available / Used)
                        </div>
                        Name
                    </th>
                    <th>Store</th>
                    <th>Manage CSV</th>
                    <th style={{minWidth: "134px"}}>Manage</th>
                    <th style={{minWidth: "124px"}}>Created At</th>
                </tr>
                </thead>
                <tbody className="[&>*]:border">
                    {props.loader ? (
                        <tr className="[&>*]:border-r-[1px] animate-pulse">
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        <td>
                            <div className="h-2 bg-slate-700 rounded" />
                        </td>
                        </tr>
                    ) : !props.data.length ? (
                        <tr className="[&>*]:border-r-[1px]">
                        <td colSpan={7} className="font-bold text-gray-700">
                            No Data to Show
                        </td>
                        </tr>
                    ) : (
                        props.data.map((e: any, i) => (
                        <tr className="[&>*]:border-r-[1px]">
                            <td>{(props.page - 1) * props.limit + ++i}</td>
                            <td
                            className="hover:cursor-pointer"
                            onClick={() => {
                                navigate(`/dashboard/yamwatch/${e?.sku}`);
                            }}
                            >
                            {
                                e?.coupon_enabled ? 
                                <div style={{"fontSize": "10pt"}}> {`( ${e?.available || 0} / ${e?.used || 0} )`}</div>
                                : null
                            }
                            {e?.name}
                            </td>
                            <td>
                            <div style={{ display: "flex", minWidth: "100px" }}>
                                {/* {e?.os?.includes("wear") && <a className="store-img" href={e?.wear_link} target="_blank" rel="noopener noreferrer"> <img src={ googlePlay } alt="" /> </a> }
                                {e?.os?.includes("tizen") && <a className="store-img" href={e?.tizen_link} target="_blank" rel="noopener noreferrer"> <img src={ galaxyStore } alt="" /> </a> } */}
                            </div>
                            </td>
                            <td>
                            <div>
                                <div
                                    className={`text-white ${ props.isUploadTarget[e.sku] || e?.coupon_enabled ? 
                                        "bg-[#808080]" : "bg-[#0EB4F3] hover:bg-[#58c7f3]"}  
                                        font-medium text-center rounded-lg h-fit text-sm m-4 mt-5 px-2 py-2.5 w-24`}
                                    style={{ margin: "0.2rem", display:"inline-block", cursor:"pointer" }}
                                    onClick={ () => handleUpload(e) }
                                >
                                    Upload
                                </div>
                                <div
                                    className={`text-white ${ props.isUploadTarget[e.sku] === false || !e?.coupon_enabled ? 
                                        "bg-[#808080]" : "bg-[#d32f2f] hover:bg-[#dd6868]"}  
                                        font-medium text-center rounded-lg h-fit text-sm m-4 mt-5 px-2 py-2.5 w-24`}
                                    style={{ margin: "0.2rem", display:"inline-block", cursor:"pointer" }}
                                    onClick={ () => handleDelete(e) }
                                >
                                    Delete
                                </div>
                                
                            </div>
                            </td>
                            <td>
                            <div>
                                <div>
                                <span>Get</span>
                                <Switch 
                                    color={ "error"}
                                    id={e?.sku + "get"}
                                    name="enable_get_coupon"
                                    checked={ props.toggleTmpVal[e.sku].enable_get_coupon }
                                    disabled={ props.isUploadTarget[e.sku] === false  || props.toggleTmpVal[e.sku].enable_now_coupon || e.coupon_enabled === false ? true : false }
                                    onChange={ (ev, val) => { 
                                                            couponManage(val, 
                                                                        ev.target.name,
                                                                        e?.sku, 
                                                                        props.setToggleTmpVal 
                                                                        ) 
                                                            }
                                            } 
                                />
                                </div>
                                <hr/>
                                <div>
                                    <span>Now</span>
                                    <Switch 
                                        id={e?.sku + "now"}
                                        name="enable_now_coupon"
                                        checked={ props.toggleTmpVal[e.sku].enable_now_coupon }
                                        disabled={ props.toggleTmpVal[e.sku].enable_get_coupon ? true : false }
                                        onChange={ (ev, val) => { 
                                                                couponManage(val, 
                                                                            ev.target.name, 
                                                                            e?.sku,
                                                                            props.setToggleTmpVal 
                                                                            ) 
                                                                } 
                                                } 
                                    />
                                </div>
                                <div>
                                    {/* Free는 now, get을 사용하지 않을 예정이므로 따로 disabled를 구현하지 않음 */}
                                    <span>Free</span>
                                    <Switch 
                                        id={e?.sku + "free"}
                                        name="enable_free_coupon"
                                        checked={ props.toggleTmpVal[e.sku].enable_free_coupon }
                                        onChange={ (ev, val) => { 
                                                                couponManage(val, 
                                                                            ev.target.name, 
                                                                            e?.sku,
                                                                            props.setToggleTmpVal 
                                                                            ) 
                                                                } 
                                                } 
                                    />
                                </div>
                            </div>
                            </td>
                            <td style={{minWidth: "183px"}}>
                            {moment(e?.created_at).format("YYYY/MM/DD HH:mm:ss")}
                            </td>
                        </tr>
                        ))
                    )}
                </tbody>
            </table>
            </div>
            { props.loader || props.totalPages <= 1 ? undefined : (
                <TablePagination 
                    curPage={props.page}
                    setCurPage={props.setPage}
                    lastPage={props.totalPages}
                />
            )}
        </>
    )
}